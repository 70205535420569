/** Register user by invitation code.
Registration does the following steps:
 - Check the invitation token.
 - Resets user's password.
 - Logs the user in.
 - Redirects to the profile editing page, passing ?then along.

URL parameters:
 - then: the URL to redirect to after successful registration.
*/

import React from 'react';
import { Redirect } from 'react-router';

import queryString from 'query-string';

import * as apiAccess from '../apiAccess.js';
import * as cookieJar from '../cookieJar.js';

import {
  Button,
  Checkbox,
  FlexStack,
  Heading,
  InputField,
  Layout,
  LayoutColumn,
  Loader,
  Text,
  TextLink,
} from '@click-therapeutics-org/ct-components';

import {
  FlexFormPage,
  FlexPageWithTermsFooter,
  SingleColumnLayout,
} from './common';

import { PasswordEntry } from './PasswordEntry';

import {
  findProblemsInPassword,
  eventHandlerPreventingDefault,
  getServerErrorMessage,
  isServerFailure,
  wireUp,
} from '../util';

import { actionType } from '../actions/register_by_token';
import { navigationComplete } from '../actions/navigation';

const renderCheckingInvitationLink = () => (
  <FlexFormPage>
    <Loader variant='pageLoader' text='Checking your invitation link...'/>
  </FlexFormPage>
);

const renderRedirectOnSuccess = (props) => {
  const urlParams = queryString.parse(props.location.search, {decode: true});
  const placeAfterSuccess = urlParams['then'] || null;
  if (placeAfterSuccess) {
    window.location = placeAfterSuccess; // We leave our app and its state.
  }
  return (<Redirect to="/login"/>);  // Redirect to profile page within our SPA.
};

const renderValidationError = () => (
  <FlexPageWithTermsFooter>
    <Layout template="Auth">
      <LayoutColumn>
        <FlexStack direction="column" justify="between" align='center'>
          <Heading variant="title3" element="h3">
            Expired invitation link
          </Heading>
          <Text
            element = "div"
            variant = "secondary"
          >
            Sorry! This invitation link
            is no longer valid, or expired.
          </Text>
        </FlexStack>
      </LayoutColumn>
    </Layout>
  </FlexPageWithTermsFooter>
);

const renderTryLaterError = (message) => (
  <FlexPageWithTermsFooter>
    <SingleColumnLayout align='center'>
    <Heading variant="title3" element="h3">
      Something went wrong
    </Heading>
    <Text
      element = "div"
      variant = "primary"
    >
      Sorry! {message}.
    </Text>
    <Text
      element = "div"
      variant = "primary"
    >
      What you can do:
      <ul>
        <li>Reload this page.</li>
        <li>Click on the invitation link again later.</li>
        <li>Contact tech support.</li>
      </ul>
    </Text>
    </SingleColumnLayout>
  </FlexPageWithTermsFooter>
);

const renderEditor = (props) => {
  // Show the registration form, possibly with disable controls and a message.
  const passwordProblems = findProblemsInPassword(props.password || '');
  const submitButtonDisabled = (
    props.running || (passwordProblems.size > 0) || ! props.termsAccepted);

  return (
  <FlexPageWithTermsFooter>
    <form onSubmit={props.submitFormWith(props)} style={{minWidth: '33%'}}>
      <SingleColumnLayout align='left'>
        <Heading variant="title2" element="h2">
          Welcome to Click Therapeutics!
        </Heading>
        <InputField readOnly={true}
                   variant = 'text'
                   label='Email'
                   value = {props.identifier}
        />
        <PasswordEntry value = {props.password}
                       disabled = {props.running}
                       label ='Choose a password'
                       error = {props.errorMessage}
                       passwordProblems = {passwordProblems}
                       autoFocus = {true}
                       onChange = {props.inputOnChangeOf('password')}
        />
        <FlexStack grow='1' inline={false} directopn='row'>
          <div style={{flexGrow: 0, flexShrink: 0}}>
            <Checkbox name='termsAccepted'
                      checked={props.termsAccepted}
                      onChange={props.checkboxOnChangeOf('termsAccepted')}/>
          </div>
          {
            // The ugly formatting here is the onlhy way to avoid whitespace elision
            // between the text and the links.
            // See https://stackoverflow.com/a/33836257/223424
          }
          <div style={{flexGrow: 1}}>
              By registering an account, you agree to the <TextLink
              href = "https://www.clicktherapeutics.com/terms.html"
              external={true}
                >Terms & Conditions</TextLink
                > and <TextLink href = "https://www.clicktherapeutics.com/privacy.html"
              external={true}
              style = {{alignSelf: "flex-end"}}
            > Privacy Policy</TextLink>.
          </div>
        </FlexStack>
        <Button submit={true}
                rounded={true}
                loading={props.running}
                disabled={submitButtonDisabled}
        >
          REGISTER
        </Button>
      </SingleColumnLayout>
    </form>
  </FlexPageWithTermsFooter>
  );
};


const RegisterByToken = (props) => {
  props.navigationComplete('RegisterByToken');

  // NOTE: This page has a lot of wildly different rendering states,
  // because it makes a number of API calls to function. We do not
  // put these states at different URLs becuase we don't want them to
  // be addressable externally. So we have to keep the state internally.

  // Check transitional states.

  switch (props.actionType) {
  case actionType.INITIAL: {
    props.startTokenValidation(props.match.params.token); // The .match is nserted by react-router's <Route>.
    return renderCheckingInvitationLink();
  }
  // Validation is special, it does not show the editor yet:
  case actionType.validateToken.STARTED: return renderCheckingInvitationLink();
  case actionType.validateToken.ERROR: return renderValidationError();
  case actionType.validateToken.FAILURE: return renderTryLaterError('We are unable to process your invitation right now');
  // Show editor; if an API call is underway is will render disabled.
  case actionType.validateToken.SUCCESS:
  case actionType.register.STARTED:
  case actionType.register.PASSWORD_ERROR:
  case actionType.edit.TERMS_CHECKBOX_CHANGE:
  case actionType.edit.INPUTS_CHANGE: return renderEditor(props);
  // API calls went well:
  case actionType.register.SUCCESS: return renderRedirectOnSuccess(props);
  // API calls failed, normal flow broken; all we can do is to show nicer diagnostics:
  case actionType.register.PASSWORD_FAILURE: return renderTryLaterError('Setting the password failed.');
  case actionType.register.LOGIN_FAILURE: return renderTryLaterError('We could not log you in right now.');
  default: return (
    // The "should never happen" branch.
    console.error('RegisterByToken: unknown page state!', props),
    (<em>Something went wrong, check console.</em>)
  );
  }
};


RegisterByToken.myWiring = {
  mapStateToProps: (state, ownProps) => Object.assign({}, state.registerByToken),
  mapDispatchToProps: dispatch => ({
    inputOnChangeOf: (controlName) => eventHandlerPreventingDefault((event) => dispatch({
      type: actionType.edit.INPUTS_CHANGE,
      [controlName]: (event.target.value || '').trimLeft(),
    })),
    checkboxOnChangeOf: (controlName) => (event) => dispatch({
      // NOTE: we're not preventing default event handling here.
      type: actionType.edit.TERMS_CHECKBOX_CHANGE,
      [controlName]: event.target.checked,
    }),
    submitFormWith: (props) => eventHandlerPreventingDefault((event) => {
      dispatch({ type: actionType.register.STARTED });
      callRegistrationFlow(props.token, props.identifier, props.password).then(dispatch);
    }),
    startTokenValidation: (rawToken) => {
      // NOTE! The unencoded token must not contain a literal percent sign.
      // It gets encoded as `%25`, and then decoded bu the router back into `%`,
      // the only one of all URL-encoded characters. Then it breaks decodeURIComponent.
      // This looks like an upstream issue. The below coded defensively.
      let token;
      try {
        token = decodeURIComponent(rawToken);
      } catch (ignoredError) {
        token = rawToken;  // Let the below code reject an invalid token as normal.
      }
      // We keep the decoded token in props.
      dispatch({ type: actionType.validateToken.STARTED, token });
      callGetPendingAccount(token).then(dispatch);
    },
    ...navigationComplete(dispatch),
  })
};

// API handlers.

async function callGetPendingAccount(token) {
  console.debug('callGetPendingAccount start; token', token);
  try {
    const response = await apiAccess.getPendingAccount(token);
    console.debug('callGetPendingAccount got', response); // XXX
    const data = response.data;
    return {
      type: actionType.validateToken.SUCCESS,
      // TODO: copy all mediums to state, show all; they are equivalent for login purposes.
      // TODO: Need to somehow mark the medium used in the invitation token.
      identifier: data.account.mediums[0].mediumAddress,
    };
  } catch (error) {
    console.error('callGetPendingAccount error', error);
    const response = (isServerFailure(error)
        ? {
          type: actionType.validateToken.FAILURE,
          errorMessage: (error.response && error.response.data) || error.message || 'Server did not explain the error',
        }
        : {
          type: actionType.validateToken.ERROR,
          errorMessage: 'Token no longer valid.'
        }
    );
    return response;
  }
}

async function callRegistrationFlow(token, identifier, password) {
  try {
    const response = await apiAccess.passwordResetComplete(token, password);
    console.debug('callRegistrationFlow passwordResetComplete:', response); // XXX
    // The API returns nothing. Also, no state change on happy path.
  } catch (error) {
    console.error('callRegistrationFlow passwordResetComplete', error);
    const type = isServerFailure(error) ? actionType.register.PASSWORD_FAILURE : actionType.register.PASSWORD_ERROR;
    // TODO: extract grpc-message and grpc-status headers.
    return {
      type,
      errorMessage: getServerErrorMessage(error),
    };
  }
  try {
    const response = await apiAccess.createUserSession(identifier, password);
    console.debug('callRegistrationFlow createUserSession:', response); // XXX
    const data = response.data;
    cookieJar.setSessionCookie({
      identifier, accountId: data.account.id, authToken: data.authToken, sessionToken: data.sessionToken,
    });
    return {
      type: actionType.register.SUCCESS,
      identifier,
    };
  } catch (error) {
    console.error('callRegistrationFlow createUserSession', error);
    const type = isServerFailure(error) ? actionType.register.LOGIN_FAILURE : actionType.register.PASSWORD_ERROR;
    return {
      type,
      errorMessage: getServerErrorMessage(error),
    };
  }
}


const RegisterByTokenC = wireUp(RegisterByToken);

export { RegisterByTokenC };
