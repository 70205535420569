import { stringConstantsWithPrefix } from '../util';

export const LOGIN = stringConstantsWithPrefix(
  'LOGIN.',
  'INPUTS_CHANGE',
  'STARTED',  // API call was just made.
  'RUNNING',  // API call is underway.
  'SUCCESS',  // API call created us a session.
  'FAILURE',  // Something went wrong, { message } has the details.
  'NOT_LOGGED_IN', // A cookie check did not find a valid session.
);
