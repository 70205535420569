/** API access via HTTP. */

import * as axiosModule from 'axios';
import axiosRetry from 'axios-retry';

import { CONFIG } from './config.js';

// Create an explicit axios client.
// TODO: Add retrying where applicable.
const axios = axiosModule.create();

axiosRetry(axios, {
  retries: 2,
  retryDelay: 8000,
  // retry on Network Error & 5xx responses
  retryCondition: axiosRetry.isRetryableError,
});

const commonOptions = {
  baseURL: CONFIG.accountApi.restServer,
  timeout: CONFIG.accountApi.timeoutMs,
};

const sponsorOptions = {
  baseURL: CONFIG.sponsorApi.restServer,
  timeout: CONFIG.sponsorApi.timeoutMs
}

export function findAccountWithId(sessionToken, accountId) {
  return axios.get(
    `/accounts/${accountId}`,
    {
      headers: { 'x-session-token': sessionToken },
      ...commonOptions,
    }
  );
}

export function createUserSession(identifier, password) {
  return axios.post(
    '/sessions', {"email_address": identifier, password},
    commonOptions,
  );
}

export function destroyUserSession(sessionToken) {
  return axios.delete(
    '/sessions',
    {
      headers: { 'x-session-token': sessionToken },
      ...commonOptions,
    }
  );
}

export function getPendingAccount(token) {
  return axios.get(
    '/password-resets:getPendingAccount',
    {
      params: { token },
      ...commonOptions,
    }
  );
}

export function isValidPasswordResetToken(token) {
  return axios.get(
    '/password-resets:isValidToken',
    {
      params: { token },
      ...commonOptions,
    }
  );
}

export function passwordResetInitiate(identifier) {
  return axios.post(
    '/password-resets:initiate', { identifier },
    commonOptions,
  );
}

export function passwordResetComplete(token, password) {
  return axios.post(
    '/password-resets:complete', {token, password},
    commonOptions,
  );
}

export async function issueLicense(uuid, handoff, oauthCode, oauthRedirectUri) {
  return axios.post(
    '/license/issue', {
      "entitlementSetUuid": uuid,
      handoff,
      "oauth_code": oauthCode,
      "oauth_redirect_uri": oauthRedirectUri,
    },
    sponsorOptions,
  );
}
