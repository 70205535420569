import React from 'react';

import { CONFIG } from '../config.js';
import * as apiAccess from '../apiAccess.js';
import * as cookieJar from '../cookieJar.js';

import {
  Button,
  Heading,
  LinkButton,
  Loader,
  Text,
} from '@click-therapeutics-org/ct-components';

import { LOGOUT } from '../actions/logout';
import { navigationComplete } from '../actions/navigation';
import {
  getServerErrorMessage,
  eventHandlerPreventingDefault,
  wireUp
} from '../util';

import {
  FlexFormPage,
  SingleColumnLayout,
} from './common';

const renderLoggedOut = (props) => (
  <FlexFormPage>
    <Heading variant="title3" element="h3">
      You have been logged out
    </Heading>
    <Text
      element = "div"
      variant = "critical"
    >{props.errorMessage}</Text>
    <LinkButton href='/#/login'>LOG IN</LinkButton>
  </FlexFormPage>
);

const renderNotLoggedIn = (props) => (
  <FlexFormPage>
    <Heading variant="title3" element="h3">
      You are not logged in
    </Heading>
    <LinkButton href='/#/login'>LOG IN</LinkButton>
  </FlexFormPage>
);

const renderLoggingOut = (props) => (
  <FlexFormPage>
    <Loader variant='pageLoader' text='Your session is being terminated...'/>
  </FlexFormPage>
)

const Logout = (props) => {
  props.navigationComplete('Logout');

  if (!props.username && props.logoutCompleted) {
    // Just logged out.
    return renderLoggedOut(props);
  }

  const sessionCookie = cookieJar.getSessionCookie();
  // Technically logout should not ever happen if not logged in.
  // Still we handle this reasonably.
  if (!sessionCookie) return renderNotLoggedIn(props);

  if (props.logoutRunning) return renderLoggingOut(props);

  // Logout action available.
  // TODO: allow to start logging out without conformation.
  const sessionToken = (sessionCookie && sessionCookie.sessionToken) || 'ERROR-NO-SESSION-TOKEN';

  return (
    <FlexFormPage contentAlign='center'>
      <SingleColumnLayout align='center'>
        <Heading variant="title3" element="h3">
          This will terminate your session
        </Heading>
        <Button submit={true}
                rounded={true}
                fullWidth
                autoFocus
                onClick={props.startLoggingOut(sessionToken)}
        >LOG OUT</Button>
        <LinkButton href='/#/'>PROFILE PAGE</LinkButton>
      </SingleColumnLayout>
    </FlexFormPage>
  );
};

Logout.myWiring = {
  mapStateToProps: (state, ownProps) => Object.assign({}, state.login),
  mapDispatchToProps: dispatch => ({
    startLoggingOut: (sessionToken) => eventHandlerPreventingDefault((event) => {
      dispatch({ type: LOGOUT.STARTED });
      callDestroyUserSession(sessionToken).then(dispatch);
    }),
    ...navigationComplete(dispatch),
  }),
};


async function callDestroyUserSession(sessionToken) {
  try {
    await apiAccess.destroyUserSession(sessionToken);
    cookieJar.removeSessionCookie();
    return {
      type: LOGOUT.SUCCESS,
    };
  } catch (error) {
    if (CONFIG.logout.deleteCookieOnLogoutError) cookieJar.removeSessionCookie();
    return {
      type: LOGOUT.FAILURE,
      message: getServerErrorMessage(error),
    };
  }
}

const LogoutC = wireUp(Logout);

export { LogoutC }
