import { actionType, initialState } from '../actions/password_reset_entry';
import { NAVIGATION } from '../actions/navigation';
import { overlay, blankValues, passPropChanges } from '../util';
import * as logging from '../logging';

const passPasswords = passPropChanges({emptyValue: ''}, 'password');

function passwordResetEntryReducer(state, action) {
  logging.debug('passwordResetEntryReducer', state, action);
  switch (action.type) {
  // Token validation phases.
  case actionType.validateToken.STARTED: return overlay(state, {
    actionType: action.type,
    running: true,
    token: action.token, // Parsed once when validation started.
    tokenValidated: false,
    identifier: '', // Explicitly not known.
    errorMessage: '',
  });
  case actionType.validateToken.ERROR:
  case actionType.validateToken.FAILURE: return overlay(state, {
    actionType: action.type,
    running: false,
    tokenValidated: false,
    errorMessage: action.errorMessage || 'API error',
  });
  case actionType.validateToken.SUCCESS: return overlay(state, {
    actionType: action.type,
    running: false,
    tokenValidated: true,
    errorMessage: '',
  });
  // Form submission phase.
  case actionType.setPassword.STARTED: return overlay(state, {
    actionType: action.type,
    running: true,
    errorMessage: '',
  });
  case actionType.setPassword.SUCCESS: return overlay(state, {
    actionType: action.type,
    running: false,
    errorMessage: '',
  });
  case actionType.setPassword.ERROR:
  case actionType.setPassword.FAILURE: return overlay(state, {
    actionType: action.type,
    running: false,
    ...blankValues('password'), // Force to type again.
    errorMessage: action.errorMessage || 'API error',
  });
  case actionType.edit.INPUTS_CHANGE: return overlay(state, {
    actionType: action.type,
    ...passPasswords(state, action),
  });
  // Commons.
  case NAVIGATION.COMPLETE: return (
    // Page changed -> reset the form.
    // Dismiss the registered page.
    action.page === 'PasswordResetEntry' ? state : initialState()
  );
  default: return state || initialState();
  };
}

export { passwordResetEntryReducer };
