// All in one file now for easy prototyping.

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';

import { Switch } from 'react-router';
import { HashRouter, Route, BrowserRouter } from 'react-router-dom';

import './App.css';

import { ProfileC } from './components/profile';
import { License } from './components/license';
import { ErrorPage } from './components/error';

import { LoginC } from './components/login';
import { LogoutC } from './components/logout';
import { loginReducer } from './reducers/login';

import { PasswordResetInitC } from './components/password_reset_init';
import { passwordResetInitReducer } from './reducers/password_reset_init';

import { PasswordResetEntryC } from './components/password_reset_entry';
import { passwordResetEntryReducer } from './reducers/password_reset_entry';

import { RegisterByTokenC } from './components/register_by_token';
import { registerByTokenReducer } from './reducers/register_by_token';
import { initialState as registerByTokenInitialState } from './actions/register_by_token';

const Page404 = () => (
  <div>
    <h1>This page does not exist.</h1>
    shrug.jpg.
  </div>
);

const App = (arg) => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/enrollment/cb' component={License} />
      <Route exact path='/error' component={ErrorPage} />
      <HashRouter>
        <Switch>
          <Route exact path='/'                component={ProfileC} />
          <Route path='/login'                 component={LoginC} />
          <Route path='/logout'                component={LogoutC} />
          <Route path='/password-reset/:token' component={PasswordResetEntryC}/>
          <Route path='/password-reset'        component={PasswordResetInitC}/>
          <Route path='/invite/:token'         component={RegisterByTokenC}/>
          <Route path='*'                      component={Page404}/>
        </Switch>
      </HashRouter>
    </Switch>
  </BrowserRouter>
);


// Top level.

function getInitialState() {
  // NOTE: login cookie is checked by the login page.
  return {
    login: {
      loginInput: '',
      passwordInput: '',
      running: false,
      loggedIn: false,
      logoutCompleted: false,
    },
    registerByToken: {
      ...registerByTokenInitialState(),
    }
  };
};

const rootReducer = combineReducers({
  login: loginReducer,
  passwordResetInit: passwordResetInitReducer,
  passwordResetEntry: passwordResetEntryReducer,
  registerByToken: registerByTokenReducer,
});

const store = createStore(rootReducer,  getInitialState());

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <App />
   </Provider>,
  rootElement
);
