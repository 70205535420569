import React from 'react';
import { Redirect } from 'react-router';

import { navigationComplete } from '../actions/navigation';

import { ReactComponent as CtLogo } from '../assets/ct-mask-icon.svg';

import {
  FlexStack,
  Heading,
  InputField,
  Layout,
  LayoutColumn,
  LinkButton,
  LinkList,
  NavigationBar,
  TextLink,
} from '@click-therapeutics-org/ct-components';

import {
  FlexPageWithTermsFooter,
} from './common';

import { wireUp } from '../util';

// TODO: Show top bar.
const Profile = (props) => {
  props.navigationComplete('profile');
  if (typeof props.username !== 'string') {
    // Profile page is only displayed in logged-in state.
    return (
      <Redirect to='/login'/>
    );
  }

  const nameOfUser = `${props.firstName || ''} ${props.lastName || ''}`.trim();;
  const renderedNameOfUser = (
    nameOfUser
      ? (
        <InputField readonly
                    trailingIcon='lock'
                    label='Your name'
                    value={nameOfUser}/>
      )
      : null
  );

  return (
    <React.Fragment>
      <NavigationBar>
        <FlexStack justify="between" spacing="none"  align="center">
          <TextLink href="/#/">
            <CtLogo style={{width: "2em", fill: "#4c4c4c"}}/>
          </TextLink>
          <FlexStack direction="row" spacing="tight" justify="end" align="center" shrink>
            <LinkButton href="/#/" trailingIcon="user" variant="secondary" transparent>
              {props.username}
            </LinkButton>
          </FlexStack>
        </FlexStack>
      </NavigationBar>
      <div style={{paddingTop: '64px'}}>
        {/* Per @bcolosky, an acceptable way to pad for nav bar height for now. */}
        <FlexPageWithTermsFooter>
          <Layout template="Auth">
            <LayoutColumn>
              <FlexStack direction="column" justify="between" align='center'>
                <FlexStack inline direction="column">
                  <Heading variant="title2" element="h2">
                    Your Profile
                  </Heading>
                  {/* This is  highly temporary. The page needs an approved design first. */}
                  <InputField readonly
                              trailingIcon='lock'
                              label='Your identifier'
                              value={props.username}/>
                  {renderedNameOfUser}
                </FlexStack>
                <LinkList size="normal" variant="primary" justify='center'>
                  <TextLink href='/#/logout'>LOG OUT</TextLink>
                  <TextLink href='/#/password-reset'>CHANGE PASSWORD</TextLink>
                </LinkList>
              </FlexStack>
            </LayoutColumn>
          </Layout>
        </FlexPageWithTermsFooter>
      </div>
    </React.Fragment>
  );
};

Profile.myWiring = {
  mapStateToProps: (state, ownProps) => ({
    username: state.login.username,
    firstName: state.login.firstName,
    lastName: state.login.lastName,
    _Profile_debug: console.log(state.login),
  }),
  mapDispatchToProps: navigationComplete,
};

const ProfileC = wireUp(Profile);

export { ProfileC };
