// Send a password reset request.

import React from 'react';
import isEmail from 'validator/lib/isEmail';

import * as apiAccess from '../apiAccess.js';

import { navigationComplete } from '../actions/navigation';

import {
  Button,
  Heading,
  InputField,
  LinkButton,
  Text,
} from '@click-therapeutics-org/ct-components';

import {
  FlexFormPage,
  FlexPageWithTermsFooter,
  SingleColumnLayout,
} from './common';
import { eventHandlerPreventingDefault, wireUp } from '../util';
import * as logging from '../logging';

import { actionType } from '../actions/password_reset_init';


const PasswordResetInit = (props) => {
  props.navigationComplete('PasswordResetInit');

  switch (props.actionType) {
  case actionType.INITIAL:
  case actionType.CONTACT_TEXT_CHANGED:
  case actionType.STARTED:
  case actionType.FAILURE: return renderInputForm(props);
  case actionType.SUCCESS: return renderSuccess();
  default: return (
    // The "should never happen" branch.
    logging.error('PasswordResetInit: unknown page state!', props),
    (<em>Something went wrong, check console.</em>)
  );
  }
};

const renderSuccess = () => (
  <FlexFormPage>
    <Heading variant="title3" element="h3">
      Password reset instructions have been sent to you.
    </Heading>
    <Text
      element = "div"
      variant = "secondary"
      align = "left"
    >
      You will receive a password reset link soon,
      provided that you've entered correct email.
    </Text>
    <LinkButton href="/">YOUR PROFILE PAGE</LinkButton>
  </FlexFormPage>
);

const renderInputForm = (props) => {
  const inputValue = props.inputValue || '';
  const emailIsValid = isEmail(inputValue);
  const submitButtonDisabled = props.running  || !inputValue.trim() || !emailIsValid;
  const errorMessage = props.errorMessage || (
    (inputValue && !emailIsValid)? 'Email is not valid' : null
  );
  return (
    <FlexPageWithTermsFooter>
      <form onSubmit={props.submitFormWith(props)}>
        <SingleColumnLayout>
            <Heading variant="title2" element="h2">Forgot your password?</Heading>
            <Text
              element = "div"
              variant = "secondary"
              align = "left"
            >
              Enter the email address associated with this account,
              and we'll send a password reset link.
            </Text>
            <InputField id="identifier-input"
                        name = "identifier-input"
                        variant = "email"
                        mode = "email"
                        leadingIcon = "email"
                        help = "Enter your email address."
                        placeholder="your@email.address"
                        minLength = '1'
                        maxLength = '128'
                        value = {inputValue}
                        disabled = {props.running}
                        error = {errorMessage}
                        autoFocus
                        onChange = {props.inputOnChange}

            />
            <Button id = 'Password-Reset-Submit'
                    icon = "email"
                    rounded="true"
                    fullWidth
                    submit = {true}
                    loading  =  {props.running}
                    disabled = {submitButtonDisabled}
            >
              SEND RESET LINK
            </Button>
            <LinkButton href="/#/login">
              BACK TO LOGIN
            </LinkButton>
        </SingleColumnLayout>
      </form>
    </FlexPageWithTermsFooter>
  );
};

PasswordResetInit.myWiring = {
  mapStateToProps: (state, ownProps) =>  Object.assign({}, state.passwordResetInit),
  mapDispatchToProps: dispatch => ({
    inputOnChange: (event) => dispatch({
      type: actionType.CONTACT_TEXT_CHANGED,
      inputValue: event.target.value,
    }),
    submitFormWith: (props) => eventHandlerPreventingDefault((event) => {
      dispatch({ type: actionType.STARTED });
      callPasswordResetInitiate(props.inputValue).then(dispatch);
    }),
    ...navigationComplete(dispatch),
  })
};

async function callPasswordResetInitiate(identifier) {
  logging.debug('callPasswordResetInitiate', identifier);
  try {
    await apiAccess.passwordResetInitiate(identifier); // NOTE: the response is empty.
    return {
      type: actionType.SUCCESS,
    };
  } catch (error) {
    logging.error('callPasswordResetInitiate error', error);
    return {
      type: actionType.FAILURE,
      errorMessage: 'We are experiencing problems. Please try again.',
    };
  }
}

const PasswordResetInitC = wireUp(PasswordResetInit);

export {
  PasswordResetInitC,
};
