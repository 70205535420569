/** Cookie-handling stuff. */

import Cookies from 'universal-cookie';

import { CONFIG } from './config.js';


const cookies = new Cookies();

/** Compute cookie-related stuff: name and options. */
function getSessionCookieOptions() {
  const options = {
    path: CONFIG.sessionCookie.path,
    expires: new Date(Date.now() + 60 * 1000 * CONFIG.sessionCookie.expirationMinutes)
  };
  if (CONFIG.sessionCookie.domain) options['domain'] = CONFIG.sessionCookie.domain;
  return options;
}

/** Set a session cookie with given payload, normally an Object which wil be stored as JSON.
    All other cookie's parameters come from the sessionCooke key of config. */
export function setSessionCookie(payload) {
  cookies.set(CONFIG.sessionCookie.name, payload, getSessionCookieOptions());
}

/** Marks the session cookie for immediate expiration. */
export function removeSessionCookie() {
  cookies.remove(CONFIG.sessionCookie.name, getSessionCookieOptions());
}

/** Returns session data from cookie in the form setSessionCooke put it; normally an Object. */
export const getSessionCookie = () => cookies.get(CONFIG.sessionCookie.name);
