import React, { Component } from 'react';
import { Redirect } from 'react-router';

import logo from '../assets/license/clickotine.png';
import googlePlayLogo from '../assets/license/google-play.png';
import frame1 from '../assets/license/frame1.png';
import frame2 from '../assets/license/frame2.png';
import frame3 from '../assets/license/frame3.png';
import frame4 from '../assets/license/frame4.png';
import { ReactComponent as AppStoreLogo } from '../assets/license/app-store.svg';

import queryString from 'query-string';
import { getSoleraOpenIdConfig } from '../config';
import * as apiAccess from '../apiAccess.js';
import '../license.css';


class License extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessCode: null,
      email: null,
      redirect: null
    };
  }

  extractEmail(license) {
    const mediums = license['attachments'][0]['account']['mediums']
    let email = 'No Email Address Found';

    mediums.forEach(medium => {
      if (medium['mediumType'] === 'EMAIL_ADDRESS') {
        email = medium['mediumAddress']
      }
    })

    return email;
  }

  async issueLicenseRequest() {
    const urlParams = queryString.parse(this.props.location.search);
    const lookupKey = urlParams['lookupKey'];
    const soleraConfig = getSoleraOpenIdConfig();
    let entitlementSetUuid = soleraConfig.entitlementSetUuid;

    const resp = await apiAccess.issueLicense(entitlementSetUuid, { lookupKey });
    const data = resp['data'];
    if (data) {
      // Already attached, thus display email information of the existing user
      if (data['status'].includes('ATTACHED') || data['status'].includes('CURRENT')) {
        const email = this.extractEmail(data['license']);
        this.setState({
          email: email,
        });
      } else {
        const code = data['license']['code']
        this.setState({
          accessCode: code,
        });
      }
    }
  }

  async componentDidMount() {
    try {
      await this.issueLicenseRequest();
    } catch (error) {
      if (parseInt(sessionStorage.getItem('counter')) < 1) {
        sessionStorage.setItem('counter', parseInt(sessionStorage.getItem('counter') || 0)+1);
        this.issueLicenseRequest();
      }
      this.setState({ redirect: "/error" });
    }
  }

  render() {
    if (this.state.redirect) {
      const urlParams = queryString.parse(this.props.location.search);
      // preserve OIDC state params for 'Try Again' button on error page:
      // if you do not pass the OIDC state, the 'Try Again' button on the /error page will route you to back /enrollment/cb, which redirects to Solera login
      // passing the OIDC state will allow the 'Try Again' button to route you to /enrollment/cb?state=currentState, which redirects you to the current sponsor login
      const redirectTarget = {
        pathname: this.state.redirect,
        state: {
          oidcState: urlParams['state']
        }
      }
      return <Redirect to={redirectTarget} />
    }

    let { accessCode, email } = this.state;
    let codeSegment = <div className="code">{accessCode}</div>;
    let titleSegment = <div className="title">Start your quit plan!</div>;
    let subtitleSegment = <div>To get started, download the app and enter the access code below to activate Clickotine on your phone</div>;
    let installSegment = <div className="subtitle">How to install and activate Clickotine on your phone?</div>;
    let loginOrSignUp = 'Sign Up';
    let accessCodeLanguage = 'Enter the access code on the top of this page';
    let signUpOrLogInFrame = frame1;


    if (email) {
      codeSegment = <div className="email">{email}</div>;
      titleSegment = <div className="subtitle">You have successfully registered</div>;
      subtitleSegment = <div>Our records show that you've set up your Clickotine account using the email below</div>;
      installSegment = <div className="subtitle">How to log into Clickotine on the phone?</div>;
      loginOrSignUp = 'Log In';
      accessCodeLanguage = 'Enter your email and password to log in';
      signUpOrLogInFrame = frame4;
    }

    return (
      <React.Fragment>
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <div className="license">
          <div className="row">
            <div className="logo">
              <img src={logo} className="clickotine" alt="Logo" />
              <div className="logoText">Clickotine®</div>
            </div>
          </div>

          <div className="column">
            {titleSegment}
            {subtitleSegment}
            <div className="code-frame">
              {codeSegment}
            </div>
          </div>

          <div className="line row"></div>

          <div className="column">
            <div className="subtitle">Download Clickotine</div>
            <div className="row">
              <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/clickotine/id1065284802"><AppStoreLogo className="app-store-logo"/></a>
              <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.ct101&hl=en"><img src={googlePlayLogo} className="google-play-logo" alt="GooglePlayLogo" /></a>
            </div>
          </div>

          <div className="line row"></div>

          <div className="column">
            {installSegment}
          </div>
        </div>
        <div className="instruction">
          <div className="instruction-step">
            <div className="instruction-text">1. Download "Clickotine" on the App Store or Google Play Store</div>
            <div className="frame-background"><img alt="frame-1" src={frame3} className="frame" /></div>
          </div>
          <div className="instruction-step">
            <div className="instruction-text">2. Open the Clickotine App and choose the {loginOrSignUp} option</div>
            <div className="frame-background"><img alt="frame-2" src={frame2} className="frame"/></div>
          </div>
          <div className="instruction-step">
            <div className="instruction-text">3. {accessCodeLanguage}</div>
            <div className="frame-background"><img alt="frame-3" src={signUpOrLogInFrame} className="frame"/></div>
          </div>

        </div>

        <div className="license">
          <div className="mobile-download">
            <div className="line row"></div>
            <div className="column">
              <div className="subtitle">Download Clickotine</div>
              <div className="row">
                <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/clickotine/id1065284802"><AppStoreLogo className="app-store-logo"/></a>
                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.ct101&hl=en"><img src={googlePlayLogo} className="google-play-logo" alt="GooglePlayLogo" /></a>
              </div>
            </div>
          </div>
          <div className="line row"></div>
          <div className="column">
            <div className="subtitle">Having trouble?</div>
            Contact us via <div><a className="link" target="_blank" rel="noopener noreferrer" href = "mailto: support@clicktherapeutics.com">support@clicktherapeutics.com</a></div>
          </div>
          <div className="column footer">
            <div>
              <a className="link" target="_blank" rel="noopener noreferrer" href="https://www.clicktherapeutics.com/terms.html">Terms & Conditions</a>&nbsp;&nbsp;&nbsp;
              <a className="link" target="_blank" rel="noopener noreferrer" href="https://www.clicktherapeutics.com/privacy.html">Privacy Policy</a>
            </div>
            <div>&copy; 2020 Click Therapeutics </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

export { License };
