// NOTE: these are not a component's actions; they describe general navigation.

import { stringConstantsWithPrefix } from '../util';

export const NAVIGATION = stringConstantsWithPrefix(
  'NAVIGATION.',
  'COMPLETE',  // A page has was navigated to. { page } contains its component name.
);

// Drop-in for mapDispatchToProps: `...navigationComplete(dispatch)`.
export const navigationComplete = (dispatch) => ({
  navigationComplete: (page) => dispatch({ type: NAVIGATION.COMPLETE, page })
});
