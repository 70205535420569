import { actionType, initialState } from '../actions/register_by_token';
import { NAVIGATION } from '../actions/navigation';
import { firstDefined, overlay } from '../util';

function passAsStrings (...names) {
  return function(state, action, mapFunc) {
    const result = {};
    for (const n of names) {
      var value = firstDefined(action[n], state[n], '');
      if (mapFunc) value = mapFunc(value);
      result[n] = value;
    }
    return result;
  };
}

const passAllFields = passAsStrings('password');

/*
The page has four major stages:
  - Running auto-check of the token.
    - started.
    - failed. // API crashed; can click the invitation link again.
  - Editing of account details whan token check completed.
    - inputs changed.
  - Resetting the password and creating a session when submit was button pressed.
    - started.
    - rejected. // Bad password, can redo.
    - failed. // API crashed; can click the invitation link again.
  - Create a session when password reset completed.
    - completed.
    - failed.  // Can go to the login page.

Porps members:
  - actionType: action type that happened last.
  - identifier: as extracted from token.
  - password1, password2: password edits data.
  - runinng: whether an API call is running.
  - message: an (error) message from an API call.
  - tokenValidated: whether the token has been validated.

// TODO: Use PropTypes.

*/


function registerByTokenReducer(state, action) {
  // console.debug('RegisterByTokenReducer', state, action);
  switch (action.type) {
  // Token validation phases.
  case actionType.validateToken.STARTED: return overlay(state, {
    actionType: action.type,
    running: true,
    token: action.token, // Parsed once when validation started.
    tokenValidated: false,
    identifier: '', // Explicitly not known.
    errorMessage: '',
  });
  case actionType.validateToken.ERROR:
  case actionType.validateToken.FAILURE: return overlay(state, {
    actionType: action.type,
    running: false,
    tokenValidated: false,
    errorMessage: action.errorMessage || 'API error',
  });
  case actionType.validateToken.SUCCESS: return overlay(state, {
    actionType: action.type,
    running: false,
    tokenValidated: true,
    identifier: action.identifier,
    errorMessage: '',
  });
  // Form submission phases.
  case actionType.register.STARTED: return overlay(state, {
    actionType: action.type,
    running: true,
    errorMessage: '',
  });
  case actionType.register.SUCCESS: return overlay(state, {
    actionType: action.type,
    running: false,
    errorMessage: '',
  });
  case actionType.register.PASSWORD_ERROR:
  case actionType.register.PASSWORD_FAILURE:
  case actionType.register.LOGIN_ERROR:
  case actionType.register.LOGIN_FAILURE: return overlay(state, {
    actionType: action.type,
    running: false,
    password: '',  // Force to type again.
    errorMessage: action.errorMessage || 'API error',
  });
  case actionType.edit.INPUTS_CHANGE: return overlay(state, {
    actionType: action.type,
    ...passAllFields(state, action)
  });
  case actionType.edit.TERMS_CHECKBOX_CHANGE: return overlay(state, {
    actionType: action.type,
    termsAccepted: action.termsAccepted,
  });
  // Commons.
  case NAVIGATION.COMPLETE: return (
    // Page changed -> reset the form.
    // Dismiss the registered page.
    action.page === 'RegisterByToken' ? state : initialState()
  );
  default: return state || initialState();
  };
}

export { registerByTokenReducer };
