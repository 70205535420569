import { stringConstantsWithPrefix, blankValues } from '../util';

const prefix = 'REG-BY-TOKEN.';

const INITIAL = prefix + 'INITIAL'; // The page just loaded.

export const edit = stringConstantsWithPrefix(
  prefix + 'EDIT.',
  'INPUTS_CHANGE',
  'TERMS_CHECKBOX_CHANGE',
);

export const validateToken = stringConstantsWithPrefix(
  prefix + 'VALIDATE.',
  'STARTED',  // API call was just made.
  'SUCCESS',  // API call completed, accepted imputs.
  'ERROR',    // API call completed, rejected inputs.
  'FAILURE',  // API call failed to complete.
);

export const register = stringConstantsWithPrefix(  // Melds two consecutive API calls.
  prefix + 'REGISTER.',
  'STARTED',
  'PASSWORD_ERROR',  // Password reset API call completed, did not like the password.
  'PASSWORD_FAILURE', // Password reset API call did not complete.
  // No separate state a between successful reset and login start.
  'LOGIN_FAILURE', // Login API call did not complete, OR rejected our credentials.
  'SUCCESS', // The whole pipeline completed.
);

export const actionType = {
  INITIAL, edit, validateToken, register,
};

export const initialState = () => ({
  actionType: INITIAL,
  termsAccepted: false,
  ...blankValues('identifier', 'password', 'errorMessage', 'token'),
  running: false,
  tokenValidated: false,
});
