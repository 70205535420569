// Odds and ends.

import { connect } from 'react-redux';


import { MIN_LOGIN_LENGTH, MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from './constants';

export function delayed(value, delay=1000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(value), delay);  // TODO: clearInterval().
  });
}

export const overlay = (target, update) => {
  // Uncomment for debugging if needed:  console.debug('Updating state:', update);
  return Object.assign({}, target, update);
};

/**
 * Returns the first non-undefined value of `values`.
 */
export function firstDefined(...values) {
  for (const v of values) {
    if (v !== undefined) return v;
  }
  return undefined;
}


/** For each name, picks a value form action, then satate, then emptyValue, to find the first non-undefined value.
If mapValue is defined, the value is replaced by mapValue(value).

The outer function returns a function to which one can easily pass Redux's state and action.
The inner function returns an object with keys from names and values obtained as above.

Example usage:
const passFieldsAsStrings = passPropChanges({ emptyValue: '', mapValue: s => s.toUpperCase() }, 'foo', 'bar');
// ...
case SOME_ACTION: return overlay(state, passFieldsAsStrings(state, action));
*/
export function passPropChanges ({emptyValue, mapValue}, ...names) {
  return function(state, action) {
    const result = {};
    for (const n of names) {
      let value = firstDefined(action[n], state[n], emptyValue);
      if (mapValue) value = mapValue(value);
      result[n] = value;
    }
    return result;
  };
}


/** Connect copmponent's mappers to Redux.
 * @param component is expected to have .myWiring = {mapStateToProps, mapDispatchToProps}.
 */
export function wireUp(component) {
  if (!component.myWiring.mapStateToProps) {
    console.warn('wireUp: component has not state mapper!', component);
  }
  return connect(component.myWiring.mapStateToProps,
                 component.myWiring.mapDispatchToProps)(component);
}

/**
 * @param {Array<string>} names keys to fill with blank string.
 * @return {Object} with names as keys, and '' as values.
 */
export const blankValues = (...names) => {
  const result = {};
  for (const n of names) result[n] = '';
  return result;
};


/** Creates a read-only objects with attribute names and values passed as arguments.
E.g. `stringConstantsWithPrefix('foo_', 'a', 'b')` produces
`{a: 'foo_a', b: 'foo_b' }`.
*/
export function stringConstantsWithPrefix(prefix, ...strings) {
  const constStore = {};
  for (const s of strings) constStore[s] = prefix + s;
  return Object.freeze(constStore);
}

/**
 * Same as `stringConstantsWithPrefix`, but with zero prefix,
 * and no argument for the prefix.
 */
export function stringConstants(...strings) {
  return stringConstantsWithPrefix('', ...strings);
}

export const checkNonEmpty = (s, controlName='<CONTROL>') => (!(s || '').trim()) ? `${controlName} is empty` : null;

const VALID_USERNAME_BEGIN_RX = /^[a-z]/;
const VALID_USERNAME_END_RX = /[a-z0-9]$/;
const VALID_USERNAME_MIDDLE_RX = /^.[a-z0-9_\-.]*.$/;

/** Returns an error message if username string is invalid, else nill. */
export function checkUsernameString(s, controlName='Name', minLength=MIN_LOGIN_LENGTH) {
  if (!s) return `${controlName} is empty.`;
  if (!VALID_USERNAME_BEGIN_RX.test(s)) return `${controlName} must start with a letter.`;
  if (!VALID_USERNAME_END_RX.test(s)) return `${controlName} must end with a letter or number.`;
  if (s.length < minLength) return `${controlName} must be ${minLength} or more characters.`;
  if (!VALID_USERNAME_MIDDLE_RX.test(s)) return `${controlName} must only contain letters, numbers, dot, minus, or underscore.`;
  return null;
}

/** Returns an error message if password string is invalid, else nill. */
export function checkPasswordString(s, controlName='Password', minLength=MIN_PASSWORD_LENGTH) {
  if (!s) return `${controlName} is empty`;
  if (s.startsWith(' ')) return `${controlName} starts with whitespace.`;
  if (s.endsWith(' ')) return `${controlName} ends with whitespace.`;
  if (s.length < minLength) return `${controlName} must be ${minLength} or more characters.`;
  if (s.toUpperCase() === s) return `${controlName} must contain at least one lower case letter`;
  if (s.toLowerCase() === s) return `${controlName} must contain at least one upper case letter`;
  if (!(/\d/.test(s))) return `${controlName} must contain at least one number`;
  for (const c of s) {  // Only allow printable ASCII.
    if ((c < ' ') || ('c' > '~')) return `${controlName} has invalid characters.`;
  }
  return null;
}

export const PASSWORD_PROBLEMS = Object.freeze({
  'LENGTH': `Password must be ${MIN_PASSWORD_LENGTH} to ${MAX_PASSWORD_LENGTH} characters long`,
  'INVALID_CHARS': 'Password must contain only printable ASCII characters',
  'WHITESPACE_AROUND': `Password must not start or end with whitespace`,
  'MANDATORY_UPPER_CASE': `Password must contain at least one upper case letter`,
  'MANDATORY_LOWER_CASE': `Password must contain at least one lower case letter`,
  'MANDATORY_NUMBER': `Password must contain at least one number`
});

export function findProblemsInPassword(s) {
  const problems = new Set();
  if (s.startsWith(' ') || s.endsWith(' ')) problems.add('WHITESPACE_AROUND');
  // Normally the control's length limitation should not even allow an excessively long password.
  if ((s.length < MIN_PASSWORD_LENGTH) || (s.length > MAX_PASSWORD_LENGTH)) problems.add('LENGTH');
  if (s.toUpperCase() === s) problems.add('MANDATORY_LOWER_CASE');
  if (s.toLowerCase() === s) problems.add('MANDATORY_UPPER_CASE');
  if (!(/\d/.test(s))) problems.add('MANDATORY_NUMBER');
  for (const c of s) {  // Only allow printable ASCII.
    if ((c < ' ') || (c > '~')) {
      problems.add('INVALID_CHARS');
      break;  // JS's strings don't behave like lists, so no `.some()` :(
    }
  }
  return problems;
}

/** Creates an event handler that prevents the default event handling.
 Runs `effect(event)` after the default handling was disabled; its return value is discarded (should be void anyway).
 Usage: `onSubmit = eventHandlerPreventingDefault((event) => { console.log(event); })`.
*/
export function eventHandlerPreventingDefault(effect) {
  return function nonPropagatingHandler(event) {
    event.preventDefault(); // Do not do the default action (submit a form, etc).
    effect(event);
    return false; // Prevent the default action anyway.
  };
}

/** Check if the HTTP error looks like a server failure. */
// NOTE: 429 Rate Limit Exceeded is not considered a failure, even though the actual handler
// wasn't likely invoked. It's a state when a user can try to resubmit a form.
export const isServerFailure = (error) => {
  let code = error.response && error.response.status;
  return (!code || (code >= 500));
};

/** Tries to extract an error message from an Axios error; if none, uses the default. */
export function getServerErrorMessage(error, defaultMessage = 'Server did not explain the error') {
  return ((error.response && error.response.data) ||
          (error.response && error.response.headers && error.response.headers['grpc-message']) ||
          error.message ||
          defaultMessage
  );

}
