import { LOGIN } from '../actions/login';
import { LOGOUT } from '../actions/logout';
import { NAVIGATION } from '../actions/navigation';
import { overlay, firstDefined } from '../util';


// NOTE: The same reducer manages logins and logouts,
// because they operate on the same state.

function loginReducer(state, action) {
  // console.log('loginReducer', state, action); // XXX
  switch (action.type) {
  case LOGIN.STARTED: return overlay(state, {
    loginRunning: true,
    errorMessage: '',
  });
  case LOGIN.SUCCESS: return overlay(state, {
    loginRunning: false,
    username: action.username,
    firstName: action.firstName,
    lastName: action.lastName,
    errorMessage: '',
    loggedIn: true,
  });
  case LOGIN.FAILURE: return overlay(state, {
    loginRunning: false,
    username: state.username,  // preserve.
    firstName: null,
    lastName: null,
    errorMessage: action.message,
  });
  case LOGIN.INPUTS_CHANGE: return overlay(state, {
    loginInput: firstDefined(action.loginInput, state.loginInput),
    passwordInput: firstDefined(action.passwordInput, state.passwordInput),
  });
  case LOGOUT.STARTED:  return overlay(state, {
    logoutRunning: true,
    errorMessage: null,
    passwordInput: '', // Force typing in password on login.
  });
  case LOGIN.NOT_LOGGED_IN: return overlay(state, {
    username: action.username,
    firstName: null,
    lastName: null,
    // NOTE: error message may be useful. errorMessage: '',
    logoutRunning: false,
    logoutCompleted: false,
    loggedIn: false,
  });
  case LOGOUT.SUCCESS: return overlay(state, {
    username: null,
    firstName: null,
    lastName: null,
    errorMessage: '',
    logoutRunning: false,
    logoutCompleted: true,
    loggedIn: false,
  });
  case LOGOUT.FAILURE:  return overlay(state, {
    logoutRunning: false,
    logoutCompleted: true,
    loggedIn: false,
    username: '',
    errorMessage: action.message,
  });
  case NAVIGATION.COMPLETE: return overlay(state, {
    // Page changed -> reset the form.
    logoutCompleted: state.logoutCompleted && (action.page === 'Logout'),
    // Clear logout error message on transition:
    errorMessage: state.logoutCompleted && (action.page !== 'Logout')  ? '' : state.errorMessage,
  });
  default: return state || {};
  };
}

export { loginReducer };
