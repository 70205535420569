/**
 * A configuration file for the application.
 * Since the app runs from a dumb HTTP server like S3,
 * everything has to be pre-packaged before "deployment" there,
 * and any dynamic configuration does not make sense.
 */

const DAY_IN_MINUTES = 24 * 60;
const API_CLIENT_TIMEOUT_MS = 8000;

export const CONFIG = Object.freeze({
  accountApp: {
    serverName:  `${process.env.REACT_APP_SERVER_ACCOUNT_PREFIX}${process.env.REACT_APP_DOMAIN}`, // For showing links to itself.
  },
  accountApi: {
    restServer: `https://${process.env.REACT_APP_ACCOUNT_API_HOST}:${process.env.REACT_APP_API_PORT}/`,
    timeoutMs: API_CLIENT_TIMEOUT_MS,
  },
  sponsorApi: {
    restServer: `https://${process.env.REACT_APP_SPONSOR_API_HOST}:${process.env.REACT_APP_API_PORT}/`,
    timeoutMs: API_CLIENT_TIMEOUT_MS,
  },
  sessionCookie: {
    name: 'ckt-cookie',
    expirationMinutes: 1 * DAY_IN_MINUTES, // Should be years long in prod.
    domain: process.env.REACT_APP_DOMAIN, // NOTE: Use a falsy value for localhost.
    path: '/',
  },
  logout: {
    deleteCookieOnLogoutError: true, // What to do when logout API fails?
    // TODO: confirm: true; // Whether /logout requires to press a button to end session.
  },
});

// TODO: remove this object and fetch partner OIDC config from sponsor-svc
const SOLERA_CONFIG = {
  staging: {
    baseUrl: 'https://solera-stg.auth0.com',
    entitlementSetUuid: '7386dde9-30ab-4840-bd41-0ef44adad831',
    clientId: 'r2aOk84zfRUGXc4EyMOeCROjy7iK7s4P',
    audience: 'wavePartnerAPI'
  },
  production: {
    baseUrl: 'https://login.wellvolution.com',
    entitlementSetUuid: '7386dde9-30ab-4840-bd41-0ef44adad831',
    clientId: 'jN9y7fz1KP825ulWfMof8uNY9DxqDS1x',
    audience: 'wavePartnerAPI'
  }
}

export function getSoleraOpenIdConfig () {
  // TODO: devise a better way of determining if the web-app is running in production
  const appDomain = process.env.REACT_APP_DOMAIN || '';
  const isProduction = appDomain.endsWith('clicktherapeutics.com');
  return isProduction ? SOLERA_CONFIG.production : SOLERA_CONFIG.staging;
}

export function getPartnerRedirectUri () {
  // base redirect URI
  return new URL(`https://${CONFIG.accountApp.serverName}/enrollment/cb`);
}

  // TODO: use discovery endpoint and openid-client for this
export function getPartnerAuthorizationUrl(baseUrl, clientId, entitlementSetUuid) {
  // append /authorize to baseUrl (instead of setting url.pathname) in case baseUrl includes a path component
  const partnerAuthorizationUrl = new URL(`${baseUrl}/authorize`);
  // set query params
  // TODO: replace Solera-specific authorization endpoint logic with a generic, sponsor-agnostic approach
  const soleraConfig = getSoleraOpenIdConfig();
  if (clientId === soleraConfig.clientId) {
    // set solera scope and audience search params: https://solera-partner-stg.azurewebsites.net/md/userinfo
    partnerAuthorizationUrl.searchParams.set('scope', 'openid profile name email phone address');
    partnerAuthorizationUrl.searchParams.set('audience', soleraConfig.audience)
  } else {
    // walgreens requires clientId to be present in the scope variable
    // TODO: do we want to include clientId in every non-solera authorization request? or do we want to make this configurable per-sponsor?
    partnerAuthorizationUrl.searchParams.set('scope', `openid ${clientId}`);
  }
  partnerAuthorizationUrl.searchParams.set('response_type', 'code');
  partnerAuthorizationUrl.searchParams.set('client_id', clientId);
  partnerAuthorizationUrl.searchParams.set('redirect_uri', getPartnerRedirectUri());
  const state = JSON.stringify({
    baseUrl, clientId, entitlementSetUuid
  })
  partnerAuthorizationUrl.searchParams.set('state', btoa(state));

  return partnerAuthorizationUrl;
}
