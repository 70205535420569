import { stringConstantsWithPrefix, blankValues } from '../util';

const prefix = 'PASSWORD-RESET.';

const INITIAL = prefix + 'INITIAL'; // The page just loaded.

export const edit = stringConstantsWithPrefix(
  prefix + 'EDIT.',
  'INPUTS_CHANGE',
);

const API_STAGES = [
  'STARTED',  // API call was just made, we are waiting for completion.
  'SUCCESS',  // API call completed, accepted data passed to it (code 2xx).
  'ERROR',    // API call completed, rejected data passed to it (code 4xx).
  'FAILURE',  // API call failed to complete (connection error, timeout, etc).
];

export const validateToken = stringConstantsWithPrefix(prefix + 'VALIDATE.', ...API_STAGES);

export const setPassword = stringConstantsWithPrefix(prefix + 'SET-PASSWORD.', ...API_STAGES);


// TODO: this is sliglty unclean, maybe come up with a more uniform structure.
// The idea is to allow writing actionType.edit.INPUTS_CHANGE instead of actionType['PASSWORD-RESET.EDIT'].INPUTS_CHANGE.
// But INITIAL is not namespaced by action group.
export const actionType = {
  INITIAL, edit, validateToken, setPassword,
};

export const initialState = () => ({
  actionType: INITIAL,
  ...blankValues('password', 'errorMessage', 'token'),
  running: false,
  tokenValidated: false,
});
