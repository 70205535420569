/** A temporary wrapper for logging calls.

It can be wired here into a logging library when we have one.
Right now we just use console output.
*/

export function debug(...values) { console.log(...values); }

export function info(...values) { console.info(...values); }

export function error(...values) { console.error(...values); }
