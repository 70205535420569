// NOTE: Current implementation asks for login and password on one page for simplicity.
// Further implementations will split to username page and password page | SSO flow page.

import React from 'react';
import { Redirect } from 'react-router';

import queryString from 'query-string';

import * as apiAccess from '../apiAccess.js';
import * as cookieJar from '../cookieJar.js';

import {
  Button,
  Heading,
  InputField,
  LinkButton,
  Loader,
} from '@click-therapeutics-org/ct-components';

import {
  FlexFormPage,
  FlexPageWithTermsFooter,
  SingleColumnLayout,
} from './common';

import { LOGIN } from '../actions/login';
import { navigationComplete } from '../actions/navigation';
import {
  eventHandlerPreventingDefault,
  getServerErrorMessage,
  wireUp,
} from '../util';


const renderCheckingSession = () => (
  <FlexFormPage>
    <Loader variant='pageLoader' text='Checking your session...'/>
  </FlexFormPage>
);


const Login = (props) => {
  props.navigationComplete('Login');

  const urlParams = queryString.parse(props.location.search);
  const placeAfterSuccess = urlParams['then'] || null;

  if (props.loggedIn) {
    // This is after we've just created a session.
    if (placeAfterSuccess) {
      window.location = placeAfterSuccess; // We leave our app and its state.
    }
    return (<Redirect to="/"/>);  // Redirect to profile page within our SPA.
  }
  // Do we have a login cookie?
  const sessionCookie = cookieJar.getSessionCookie();
  if (sessionCookie) { // Is it valid?
    props.startCheckingCookie(sessionCookie); // We can end up already logged in.
    return renderCheckingSession();
  }

  const usernameToShow = props.loginInput || props.username || '';
  const loginButtonDisabled = props.loginRunning || !props.loginInput || !props.passwordInput;
  // TODO: Top navigation bar.
  return (
    <FlexPageWithTermsFooter>
      <form onSubmit={props.loginOnClickWith(props.loginInput, props.passwordInput)}>
        <SingleColumnLayout>
          <Heading variant="title2" element="h2">
            Log in
          </Heading>
          <InputField variant = 'email'
                      mode = 'email'
                      label='Email'
                      value = {usernameToShow}
                      autoFocus
                      onChange={props.inputsOnChangeOf('loginInput')}
                      disabled = {props.loginRunning}
          />
          <InputField variant = 'password'
                      mode = 'text'
                      label='Password'
                      disabled = {props.loginRunning}
                      value = {props.passwordInput}
                      error = {props.errorMessage}
                      onChange={props.inputsOnChangeOf('passwordInput')}
          />
          <Button submit={true}
                  rounded={true}
                  fullWidth
                  loading={props.loginRunning}
                  disabled={loginButtonDisabled}
          >
            LOG IN
          </Button>
          <LinkButton href='/#/password-reset'>
            FORGOT PASSWORD
          </LinkButton>
        </SingleColumnLayout>
      </form>
    </FlexPageWithTermsFooter>
  );
};

Login.myWiring = {
  mapStateToProps: (state, ownProps) => Object.assign({}, state.login),
  mapDispatchToProps: dispatch => ({
    inputsOnChangeOf: (controlName) => (event) => dispatch({
      type: LOGIN.INPUTS_CHANGE,
      [controlName]: event.target.value,
    }),
    loginOnClickWith: (login, password) => eventHandlerPreventingDefault((event) => {
      dispatch({ type: LOGIN.STARTED });
      callCreateUserSession(login, password).then(dispatch);
    }),
    startCheckingCookie: (cookie) => {
      callFindAccountWithId(cookie.sessionToken, cookie.accountId, cookie.identifier).then(dispatch);
    },
    ...navigationComplete(dispatch),
  })
};

async function callFindAccountWithId(sessionToken, accountId, login) {
  console.debug('callFindAccountWithId', sessionToken, accountId);
  try {
    if (!/^[1-9][0-9]*$/.test(accountId)) {
      // The catch() below will handle it.
      throw new Error('Invalid account ID in cookie: ' + accountId);
    }
    const response = await apiAccess.findAccountWithId(sessionToken, accountId);
    const data = response.data;
    // TODO: check that cookie's login matches one of the mediums.
    return {
      type: LOGIN.SUCCESS,
      username: login,
      userId: data.account.id,
      firstName: data.account.firstName,
      lastName: data.account.lastName
    };
  } catch (error) {
    // Either the cookie is invalid or the token has expired.
    // This is not an error, but looks exactly like being logged out.
    // NOTE: This logs us out when API is unreachable.
    // TODO: Only log out when API returns 401/403; else show 'service unavailable' screen.
    cookieJar.removeSessionCookie();
    return {
      type: LOGIN.NOT_LOGGED_IN,
      username: login,
    };
  }
}

async function callCreateUserSession(identifier, password) {
  console.debug('callCreateUserSession', identifier, password);
  try {
    const response = await apiAccess.createUserSession(identifier, password);
    const data = response.data;
    cookieJar.setSessionCookie({
      identifier, accountId: data.account.id, authToken: data.authToken, sessionToken: data.sessionToken,
    });
    return {
      type: LOGIN.SUCCESS,
      username: identifier,
      userId: data.account.id,
      firstName: data.account.firstName,
      lastName: data.account.lastName
    };
  } catch (error) {
    const message = (
      // Technically 401 is "credentials are not valid",
      // and 403 is "credentials are valid but action is not authorized for you".
      // For logging in, we handle these two outcomes the same for now.
      // TODO: when we have account freezing, show "your account is frozen" for 403.
      (error.response && (error.response.status === 401 || error.response.status === 403))
        ? 'These credentials are not accepted.'
        : getServerErrorMessage(error)
    );
    // NOTE: even in the event of a server failure, such as timeout,
    // we handle it identically to an error such as invalid credentials anyway.
    return { type: LOGIN.FAILURE, message };
  }
}

const LoginC = wireUp(Login);

export { LoginC };
