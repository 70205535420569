import React, { Component } from 'react';

import logo from '../assets/license/clickotine.png';
import submitButton from '../assets/license/submit.png';
import { ReactComponent as ErrorIcon } from '../assets/error.svg';
import '../license.css';
import { getPartnerRedirectUri } from '../config';

class ErrorPage extends Component {
  render() {    
    const redirectUri = getPartnerRedirectUri();
    // preserve oidc state variable if passed in the location state
    if (this.props.location.state.oidcState) {
      redirectUri.searchParams.set('state', this.props.location.state.oidcState)
    }
    return (
      <React.Fragment>
        <div className="license">
          <div className="column">
            <div className="logo">
              <img src={logo} className="clickotine" alt="Logo" />
              <div className="logoText">Clickotine®</div>
            </div>
          </div>

          <div className="column">
            <ErrorIcon className="error-icon"/>
          </div>

          <div className="column">
            <div className="subtitle error">Sorry…something went wrong</div>
            <div className="error-text">We’re experiencing some difficulties here. If you were referred here from another source please try that process again:</div>
            <a href={redirectUri.href}><img src={submitButton} className="error-img" alt="Btn" /></a>
            <div className="error-text">Still having issues? Contact our customer support via:</div>
            <div><a className="link" target="_blank" rel="noopener noreferrer" href = "mailto: support@clicktherapeutics.com">support@clicktherapeutics.com</a></div>
          </div>
  
          <div className="column footer">
            <div>
              <a className="link" target="_blank" rel="noopener noreferrer" href="https://www.clicktherapeutics.com/terms.html">Terms & Conditions</a>&nbsp;&nbsp;&nbsp;
              <a className="link" target="_blank" rel="noopener noreferrer" href="https://www.clicktherapeutics.com/privacy.html">Privacy Policy</a>
            </div>
            <div>&copy; 2020 Click Therapeutics </div>
          </div>
        </div>
  
      </React.Fragment>
    );
  }
}


export { ErrorPage };
