import { actionType, initialState } from '../actions/password_reset_init';
import { NAVIGATION } from '../actions/navigation';
import { overlay, passPropChanges } from '../util';

const passInputValue = passPropChanges({emptyValue: ''}, 'inputValue');

function passwordResetInitReducer(state, action) {
  switch (action.type) {
  case actionType.CONTACT_TEXT_CHANGED: return overlay(state, {
    actionType: action.type,
    ...passInputValue(state, action),
  });
  case actionType.STARTED: return overlay(state, {
    actionType: action.type,
    errorMessage: '',
    running: true,
  });
  case actionType.SUCCESS: return overlay(state, {
    actionType: action.type,
    errorMessage: '',
    running: false,
  });
  case actionType.FAILURE: return overlay(state, {
    actionType: action.type,
    errorMessage: action.errorMessage,
    running: false,
  });
  case NAVIGATION.COMPLETE: return (
    // Page changed -> reset the form.
    action.page === 'PasswordResetInit' ? state : initialState()
  );
  default: return state || initialState();
  }
};

export { passwordResetInitReducer };
