import { stringConstantsWithPrefix } from '../util';

const prefix = 'REQUEST-PASSWORD-RESET.';

const API_STAGES = [
  'STARTED',  // API call was just made, we are waiting for completion.
  'SUCCESS',  // API call completed, accepted data passed to it (code 2xx).
  // NOTE: no ERROR. The input cannot be rejected; even if it's rejected, we don't tell the user.
  'FAILURE',  // API call failed to complete (connection error, timeout, etc).
];

export const actionType = stringConstantsWithPrefix(
  prefix,
  'INITIAL',
  'CONTACT_TEXT_CHANGED',
  ...API_STAGES
);



export const initialState = () => ({
  actionType: actionType.INITIAL,
  inputValue: '',
  errorMessage: '',
  running: false,
});
